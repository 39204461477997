<template>
    <div>
        <component :is="currentComponent + 'Component'" @on-reloadRight="reloadRight" :id.sync="id" />

    </div>
</template>

<script>
export default {
  props: {
    id: Number
  },
  components: {
    invoiceComponent: (resolve) => {
      require(['./invoice/Detail'], resolve)
    }
  },
  data () {
    return {
      currentComponent: 'invoice'
    }
  },
  created () {
    this.initListComponent()
  },
  methods: {
    // 根据当前的任务类型加载不同的列表页面
    initListComponent () {
      if (this.taskType === 1) {
        this.currentComponent = 'invoice'
      }
    },
    reloadRight (id) {
      this.$emit('on-reloadRight', id)
    }
  }
}
</script>
